import { Router } from '@angular/router';
import { Localization } from '../../Localization';
import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/service/auth.service';
import { GetOnlyEnclosureByAdminUseCase } from 'src/app/core/usecases/enclosures/GetOnlyEnclosureByAdminUseCase';
import {
  OnlyEnclosureResponse,
} from 'src/app/data/dto/EnclosuresDTO';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  @Input() nameHeader: string;
  idUser: any;
  idAdmin: any;

  public focus;
  public location: Location;
  error: any;
  listEnclosureByAdmin: OnlyEnclosureResponse[];
  onlyEnclosures: any = [];
  image: any;
  color: any;
  style: any;

  constructor(
    private router: Router,
    public readonly localization: Localization,
    private authenticationService: AuthService,
    private readonly getOnlyEnclosureByAdminUseCase: GetOnlyEnclosureByAdminUseCase,
  ) {
    if (localStorage.getItem('idAdmin') == null) {
      this.authenticationService.currentUser.subscribe(
        (currentUser) =>
          (this.idAdmin =
            currentUser != null ? currentUser.response.intFKIDUsuario : 0)
      );
    } else {
      this.idAdmin = Number(localStorage.getItem('idAdmin'));
    }
    if (this.idAdmin !== 1) {
      localStorage.setItem('idAdmin', JSON.stringify(this.idAdmin));
    }
  }

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(
      (currentUser) =>
        (this.idUser = currentUser != null ? currentUser.response.intFKIDProfile : 0)
    );
    if (this.idUser === 1) {
      this.nameHeader = 'Inicio';
    }
    if (this.idUser === 2) {
      if (this.idAdmin === 1) {
        this.nameHeader = 'Selecciona el recinto';
      } else {
        this.nameHeader = 'COMPRAR LUGAR DE ESTACIONAMIENTO';
      }
    }
    if (this.idUser === 3) {
      this.nameHeader = 'Inicio';
    }
    if (this.idAdmin === 5) {
      this.nameHeader = 'Prepago';
    }
    this.listOnlyEnclosuresByAdmin();
  }

  listOnlyEnclosuresByAdmin(): void {
    if (this.idAdmin === 1) {
      this.image = `assets/img/ParkitHeader.png`;
      this.style = '#05216e';
    }
    else {
      const request = {
        idAdmin: localStorage.getItem('idAdmin')
      };
      this.getOnlyEnclosureByAdminUseCase.execute(request).subscribe(
        (data) => {
          this.listEnclosureByAdmin = data.response;
          this.idAdmin = this.listEnclosureByAdmin[0].idAdmin;
          localStorage.setItem('idAdmin', JSON.stringify(this.idAdmin));
          this.image = `assets/img/${this.listEnclosureByAdmin[0].nameAdmin}Header.png`;
          this.style = this.listEnclosureByAdmin[0].color;
        },
        (error) => {
          this.error = error;
        }
      );
    }
  }

  closeLogout(): void {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
    localStorage.removeItem('User');
    localStorage.clear();
  }

  onReturn(): void {
    this.router.navigate(['/']);
  }
}
