import { Observable } from 'rxjs';
import {
  LoginDTO,
  LoginResponseDTO,
  RecoveryRequestDTO,
  RecoveryResponseDTO,
  ChangePassRequestDTO,
  ChangePassResponseDTO,
  ChangeProfileRequestDTO,
  ChangeProfileResponseDTO,
  AdminsDTO,
} from '../../../data/dto/LoginDTO';

export abstract class UserRepository {
  abstract login(auth: LoginDTO): Observable<LoginResponseDTO>;
  abstract logout(): Observable<string>;
  abstract recover(request: RecoveryRequestDTO): Observable<RecoveryResponseDTO>;
  abstract changePass(request: ChangePassRequestDTO): Observable<ChangePassResponseDTO>;
  abstract changeProfile(request: ChangeProfileRequestDTO): Observable<ChangeProfileResponseDTO>;
  abstract admins(): Observable<AdminsDTO>;
}
