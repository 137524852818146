import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ParkingAreaRepository } from 'src/app/core/repositories/parking-area/ParkingAreaRepository';
import { environment } from 'src/environments/environment';
import {
    ParkingAreaRequestDTO,
    AddParkingAreaDTO,
    DeleteParkingAreaRequestDTO,
    DeleteParkingAreaResponseDTO,
    ParkingAreaResponseDTO,
    ParkingAreaUpdateDTO,
    OnlyParkingAreaDTO
} from '../../dto/ParkingAreaDTO';

@Injectable({
    providedIn: 'root',
})

export class ParkingAreaRestRepository extends ParkingAreaRepository {
    private headers: HttpHeaders;
    constructor(private http: HttpClient) {
        super();
        this.headers = new HttpHeaders({
            'Content-Type': 'application/json',
        });
    }

    listMembership(request: ParkingAreaRequestDTO): Observable<ParkingAreaResponseDTO> {
        const endpoint = environment.zonaEstacionamiento_endpoint;
        const params = new HttpParams(
            {
                fromObject: {
                    admin: request.admin,
                }
            }
        );
        const httpOptions = {
            headers: this.headers ,
            params
        };
        return this.http
            .get<ParkingAreaResponseDTO>(`${environment.url_base}/${endpoint}`, httpOptions)
            .pipe(
                map((result) => {
                    return result;
                })
            );
    }

    addParkingArea(request: AddParkingAreaDTO): Observable<ParkingAreaResponseDTO> {
        const endpoint = environment.zonaEstacionamiento_endpoint;
        const httpOptions = { headers: this.headers };
        return this.http
            .post<ParkingAreaResponseDTO>(`${environment.url_base}/${endpoint}`, JSON.stringify(request), httpOptions)
            .pipe(
                map((result) => {
                    return result;
                })
            );
    }

    updateParkingArea(request: ParkingAreaUpdateDTO): Observable<ParkingAreaUpdateDTO> {
        const id = request.id;
        const endpoint = environment.zonaEstacionamiento_endpoint;
        const httpOptions = { headers: this.headers };
        return this.http
            .put<ParkingAreaUpdateDTO>(`${environment.url_base}/${endpoint}/${id}`, JSON.stringify(request), httpOptions)
            .pipe(
                map((result) => {
                    return result;
                })
            );
    }

    deleteParkingArea(id: DeleteParkingAreaRequestDTO): Observable<DeleteParkingAreaResponseDTO> {
        const endpoint = environment.zonaEstacionamiento_endpoint;
        const idDelete = id.idDelete;
        const httpOptions = { headers: this.headers };
        return this.http
            .delete<DeleteParkingAreaResponseDTO>(`${environment.url_base}/${endpoint}/${idDelete}`, httpOptions)
            .pipe(
                map((result) => {
                    return result;
                })
            );
    }

    getOnlyParkingArea(id: number): Observable < OnlyParkingAreaDTO > {
        const endpoint = environment.only_parking_area_endpoint;
        const idEvent = id;
        const httpOptions = {
            headers: this.headers
        };
        return this.http.get<OnlyParkingAreaDTO>(`${
            environment.url_base
        }/${endpoint}/${idEvent}`, httpOptions).pipe(map((result) => {
            return result;
        }));
    }

}
