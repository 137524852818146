import { Observable } from 'rxjs';
import {
    GuardsRequestDTO,
    GetAllGuardsResponseDTO,
    AddGuardAreaRequestDTO,
    AddGuardAreaResponse,
    GuardOfAreaRequestDTO,
    GuardOfAreaResponse,
    UpdateGuardsAreaRequestDTO,
    UpdateGuardsAreaResponseDTO,
    DeleteGuardsAreaRequestDTO,
    DeleteGuardsAreaResponseDTO,
} from 'src/app/data/dto/GuardsAreaDTO';

export abstract class GuardsAreaRepository {
    abstract listGetAllGuards(
        request: GuardsRequestDTO
    ): Observable<GetAllGuardsResponseDTO>;
    abstract createGuardArea(
        request: AddGuardAreaRequestDTO
    ): Observable<AddGuardAreaResponse>;
    abstract guardOfArea(request: GuardOfAreaRequestDTO): Observable<GuardOfAreaResponse>;
    abstract updateGuardArea(
        id: UpdateGuardsAreaRequestDTO
    ): Observable<UpdateGuardsAreaResponseDTO>;
    abstract deleteGuardArea(
        id: DeleteGuardsAreaRequestDTO
    ): Observable<DeleteGuardsAreaResponseDTO>;
}
