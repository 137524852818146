<div class="alert-dialog-container">
    <div class="alert-dialog-content shadow">
        <div class="alert {{alertClass}}">
            <div class="dialog-header">
                <i class="{{iconClass}}"></i>
                <h4>{{title}}</h4>
            </div>
            <div class="dialog-body">
                <p>{{message}}</p>
            </div>
            <div class="dialog-footer">
                <button *ngFor="let btn of buttons" class="btn {{btn.cssClass || 'btn-outline-secondary'}}"
                    (click)="onButtonClick(btn)">
                    {{btn.text}}
                </button>
                <button *ngIf="buttons.length === 0" class="btn btn-outline-secondary" (click)="onClose()">
                    Cerrar
                </button>
            </div>
        </div>
    </div>
</div>