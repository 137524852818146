import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from 'src/app/auth/service/auth.service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { DiscountCodeRepository } from 'src/app/core/repositories/discountCode/DiscountCodeRepository';
import { map } from 'rxjs/operators';
import {
    DiscountCodeResponseDTO,
    DiscountCodeRequestDTO,
    DiscountCodeResponse,
    UpdateDiscountCodeRequestDTO,
    UpdateDiscountCodeResponseDTO,
    DeleteDiscountCodeRequestDTO,
    DeleteDiscountCodeResponseDTO,
    IsValidDiscountCodeRequestDTO,
    IsValidDiscountCodeResponseDTO,
} from 'src/app/data/dto/DiscountCodeDTO';

@Injectable({
    providedIn: 'root',
})

export class DiscountCodeRestRepository extends DiscountCodeRepository {
    private headers: HttpHeaders;
    constructor(private http: HttpClient, private auth: AuthService) {
        super();
        this.headers = new HttpHeaders({
            'Content-Type': 'application/json',
        });
    }

    listDiscountCode(): Observable<DiscountCodeResponseDTO> {
        const endpoint = environment.discount_code_endpoint;
        const httpOptions = { headers: this.headers };
        return this.http
            .get<DiscountCodeResponseDTO>(
                `${environment.url_base}/${endpoint}`,
                httpOptions
            )
            .pipe(
                map((result) => {
                    return result;
                })
            );
    }

    createDiscountCode(
        request: DiscountCodeRequestDTO
    ): Observable<DiscountCodeResponse> {
        const endpoint = environment.discount_code_endpoint;
        const httpOptions = { headers: this.headers };
        return this.http
            .post<DiscountCodeResponse>(
                `${environment.url_base}/${endpoint}`,
                request,
                httpOptions
            )
            .pipe(
                map((result) => {
                    return result;
                })
            );
    }

    updateDiscountCode(
        request: UpdateDiscountCodeRequestDTO
    ): Observable<UpdateDiscountCodeResponseDTO> {
        const endpoint = environment.discount_code_endpoint;
        const id = request.id;
        const httpOptions = { headers: this.headers };
        return this.http
            .put<UpdateDiscountCodeResponseDTO>(
                `${environment.url_base}/${endpoint}/${id}`,
                JSON.stringify(request),
                httpOptions
            )
            .pipe(
                map((result) => {
                    return result;
                })
            );
    }

    releaseDiscountCode(
        request: DeleteDiscountCodeRequestDTO
    ): Observable<DeleteDiscountCodeResponseDTO> {
        const endpoint = environment.release_discount_code_endpoint;
        const id = request.id;
        const httpOptions = { headers: this.headers };
        return this.http
            .put<DeleteDiscountCodeResponseDTO>(
                `${environment.url_base}/${endpoint}/${id}`,
                JSON.stringify(request),
                httpOptions
            )
            .pipe(
                map((result) => {
                    return result;
                })
            );
    }

    deleteDiscountCode(
        request: DeleteDiscountCodeRequestDTO
    ): Observable<DeleteDiscountCodeResponseDTO> {
        const endpoint = environment.delete_discount_code_endpoint;
        const id = request.id;
        const httpOptions = { headers: this.headers };
        return this.http
            .delete<DeleteDiscountCodeResponseDTO>(
                `${environment.url_base}/${endpoint}/${id}`,
                httpOptions
            )
            .pipe(
                map((result) => {
                    return result;
                })
            );
    }

    isValidDiscountCode(
        request: IsValidDiscountCodeRequestDTO
    ): Observable<IsValidDiscountCodeResponseDTO> {
        const endpoint = environment.discount_code_valid;
        const id = request.id;
        const httpOptions = { headers: this.headers };
        return this.http
            .post<IsValidDiscountCodeResponseDTO>(
                `${environment.url_base}/${endpoint}/${id}`,
                request,
                httpOptions
            )
            .pipe(
                map((result) => {
                    return result;
                })
            );
    }

}
