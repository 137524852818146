import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AuthService } from 'src/app/auth/service/auth.service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { GuardsAreaRepository } from 'src/app/core/repositories/guardsArea/GuardsAreaRepository';
import { map } from 'rxjs/operators';
import {
    GuardsRequestDTO,
    GetAllGuardsResponseDTO,
    AddGuardAreaRequestDTO,
    AddGuardAreaResponse,
    GuardOfAreaRequestDTO,
    GuardOfAreaResponse,
    UpdateGuardsAreaRequestDTO,
    UpdateGuardsAreaResponseDTO,
    DeleteGuardsAreaRequestDTO,
    DeleteGuardsAreaResponseDTO,
} from 'src/app/data/dto/GuardsAreaDTO';

@Injectable({
    providedIn: 'root',
})

export class GuardsAreaRestRepository extends GuardsAreaRepository {
    private headers: HttpHeaders;
    constructor(private http: HttpClient, private auth: AuthService) {
        super();
        this.headers = new HttpHeaders({
            'Content-Type': 'application/json',
        });
    }

    listGetAllGuards(request: GuardsRequestDTO): Observable<GetAllGuardsResponseDTO> {
        const endpoint = environment.guards_endpoint;
        const params = new HttpParams(
            {
                fromObject: {
                    admin: request.admin
                }
            }
        );
        const httpOptions = {
            headers: this.headers,
            params
        };
        return this.http
            .get<GetAllGuardsResponseDTO>(
                `${environment.url_base}/${endpoint}`,
                httpOptions
            )
            .pipe(
                map((result) => {
                    return result;
                })
            );
    }

    createGuardArea(
        request: AddGuardAreaRequestDTO
    ): Observable<AddGuardAreaResponse> {
        const endpoint = environment.guards_endpoint;
        const httpOptions = { headers: this.headers };
        return this.http
            .post<AddGuardAreaResponse>(
                `${environment.url_base}/${endpoint}`,
                request,
                httpOptions
            )
            .pipe(
                map((result) => {
                    return result;
                })
            );
    }

    guardOfArea(
        request: GuardOfAreaRequestDTO
    ): Observable<GuardOfAreaResponse> {
      const endpoint = environment.list_Guards_Of_area_endpoint;
      const params = new HttpParams({
        fromObject: {
          idZone: request.idZone,
          idEvent: request.idEvent,
        },
      });
      const httpOptions = { headers: this.headers, params };
      return this.http
          .get<GuardOfAreaResponse>(
            `${environment.url_base}/${endpoint}`,
            httpOptions
          )
          .pipe(
              map((result) => {
                  return result;
              })
          );
    }

    updateGuardArea(
        request: UpdateGuardsAreaRequestDTO
    ): Observable<UpdateGuardsAreaResponseDTO> {
        const endpoint = environment.guards_endpoint;
        const id = request.id;
        const httpOptions = { headers: this.headers };
        return this.http
            .put<UpdateGuardsAreaResponseDTO>(
                `${environment.url_base}/${endpoint}/${id}`,
                JSON.stringify(request),
                httpOptions
            )
            .pipe(
                map((result) => {
                    return result;
                })
            );
    }

    deleteGuardArea(
        request: DeleteGuardsAreaRequestDTO
    ): Observable<DeleteGuardsAreaResponseDTO> {
        const endpoint = environment.guards_endpoint;
        const id = request.id;
        const httpOptions = { headers: this.headers };
        return this.http
            .delete<DeleteGuardsAreaResponseDTO>(
                `${environment.url_base}/${endpoint}/${id}`,
                httpOptions
            )
            .pipe(
                map((result) => {
                    return result;
                })
            );
    }

}
