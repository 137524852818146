<app-sidebar (nameMenu)="emitTitle($event)"></app-sidebar>
<app-header [nameHeader]="recibeTitle"></app-header>
<div
  class="bg-image"
  [ngStyle]="{ backgroundImage: 'url(./assets/img/ImagenParkitAerea.jpg)' }"
>
  <div class="main-content">
    <router-outlet></router-outlet>
    <div class="container-fluid p-0">
      <app-footer></app-footer>
    </div>
  </div>
</div>
