import { Observable } from 'rxjs';
import {
    EventTypeResponseDTO,
    EventTypeRequestDTO,
    EventTypeResponse,
    UpdateEventTypeRequestDTO,
    UpdateEventTypeResponseDTO,
    DeleteEventTypeRequestDTO,
    DeleteEventTypeResponseDTO,
} from 'src/app/data/dto/EventTypeDTO';

export abstract class EventTypeRepository {
    abstract listEventType(): Observable<EventTypeResponseDTO>;
    abstract createEventType(
        request: EventTypeRequestDTO
    ): Observable<EventTypeResponse>;
    abstract updateEventType(
        id: UpdateEventTypeRequestDTO
    ): Observable<UpdateEventTypeResponseDTO>;
    abstract deleteEventType(
        id: DeleteEventTypeRequestDTO
    ): Observable<DeleteEventTypeResponseDTO>;
}
