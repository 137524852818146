import { Observable } from 'rxjs';
import {
    BuyTicketDTO,
    ListBuyTicketsResponseDTO,
    ResultSumaryPaymentDTO,
    SummaryBuyDTO,
    CancelPlaceResponseDTO,
    ApprovePaymentRequestDTO,
    ApprovePaymentResponseDTO,
    UpdateTicketRequestDTO,
    UpdateResponse,
    AvailablePlacesRequestDTO,
    AvailablePlacesResponseDTO,
    ReleaseTicketsRequestDTO,
    ReleaseTicketsResponseDTO,
    ChangeTicketsResponseDTO,
    ReturnTicketResponseDTO
} from 'src/app/data/dto/BuyTicketsDTO';

export abstract class BuyTicketRepository {
    abstract listBuyTickets(id: number): Observable<ListBuyTicketsResponseDTO>;
    abstract addBuyTicket(params: BuyTicketDTO): Observable<SummaryBuyDTO>;
    abstract getSumaryPayment(id: number): Observable<ResultSumaryPaymentDTO>;
    abstract cancelPlace(id: number): Observable<CancelPlaceResponseDTO>;
    abstract approvedPayment(id: ApprovePaymentRequestDTO): Observable<ApprovePaymentResponseDTO>;
    abstract updatePayment(id: UpdateTicketRequestDTO): Observable<UpdateResponse>;
    abstract acceptPayment(id: ApprovePaymentRequestDTO): Observable<ApprovePaymentResponseDTO>;
    abstract changePaymentMethod(id: ApprovePaymentRequestDTO): Observable<ApprovePaymentResponseDTO>;
    abstract availablePlaces(params: AvailablePlacesRequestDTO): Observable<AvailablePlacesResponseDTO>;
    abstract releaseTickets(id: ReleaseTicketsRequestDTO): Observable<ReleaseTicketsResponseDTO>;
    abstract changePurchasedTicket(request: ChangeTicketsResponseDTO): Observable<any>;
    abstract returnTicket(request: ReturnTicketResponseDTO): Observable<any>;
}
