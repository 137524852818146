import { Observable } from 'rxjs';
import {
    DiscountCodeResponseDTO,
    DiscountCodeRequestDTO,
    DiscountCodeResponse,
    UpdateDiscountCodeRequestDTO,
    UpdateDiscountCodeResponseDTO,
    DeleteDiscountCodeRequestDTO,
    DeleteDiscountCodeResponseDTO,
    IsValidDiscountCodeRequestDTO,
    IsValidDiscountCodeResponseDTO,
} from 'src/app/data/dto/DiscountCodeDTO';

export abstract class DiscountCodeRepository {
    abstract listDiscountCode(): Observable<DiscountCodeResponseDTO>;
    abstract createDiscountCode(
        request: DiscountCodeRequestDTO
    ): Observable<DiscountCodeResponse>;
    abstract updateDiscountCode(
        id: UpdateDiscountCodeRequestDTO
    ): Observable<UpdateDiscountCodeResponseDTO>;
    abstract releaseDiscountCode(
        id: DeleteDiscountCodeRequestDTO
    ): Observable<DeleteDiscountCodeResponseDTO>;
    abstract deleteDiscountCode(
        id: DeleteDiscountCodeRequestDTO
    ): Observable<DeleteDiscountCodeResponseDTO>;
    abstract isValidDiscountCode(
        request: IsValidDiscountCodeRequestDTO
    ): Observable<IsValidDiscountCodeResponseDTO>;
}
