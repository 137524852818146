import { Observable } from 'rxjs';
import {
    ParkingAreaRequestDTO,
    AddParkingAreaDTO,
    DeleteParkingAreaRequestDTO,
    DeleteParkingAreaResponseDTO,
    ParkingAreaResponseDTO,
    ParkingAreaUpdateDTO,
    OnlyParkingAreaDTO
} from 'src/app/data/dto/ParkingAreaDTO';

export abstract class ParkingAreaRepository {
    abstract listMembership(request: ParkingAreaRequestDTO): Observable<ParkingAreaResponseDTO>;
    abstract addParkingArea(reques: AddParkingAreaDTO): Observable<ParkingAreaResponseDTO>;
    abstract updateParkingArea(request: ParkingAreaUpdateDTO): Observable<ParkingAreaUpdateDTO>;
    abstract deleteParkingArea(id: DeleteParkingAreaRequestDTO): Observable<DeleteParkingAreaResponseDTO>;
    abstract getOnlyParkingArea(id: number): Observable<OnlyParkingAreaDTO>;
}
