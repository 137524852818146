import { Injectable } from '@angular/core';
import { BuyTicketRepository } from 'src/app/core/repositories/buyTickets/BuyTicketRepository';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import {
    BuyTicketDTO,
    ListBuyTicketsResponseDTO,
    ResultSumaryPaymentDTO,
    SummaryBuyDTO,
    CancelPlaceResponseDTO,
    ApprovePaymentRequestDTO,
    ApprovePaymentResponseDTO,
    UpdateTicketRequestDTO,
    UpdateResponse,
    AvailablePlacesRequestDTO,
    AvailablePlacesResponseDTO,
    ReleaseTicketsRequestDTO,
    ReleaseTicketsResponseDTO,
    ChangeTicketsResponseDTO,
    ReturnTicketResponseDTO
} from '../../dto/BuyTicketsDTO';

@Injectable({
    providedIn: 'root',
})

export class BuyTicketsRestRepository extends BuyTicketRepository {
    private headers: HttpHeaders;
    constructor(private http: HttpClient) {
        super();
        this.headers = new HttpHeaders({
            'Content-Type': 'application/json',
        });
    }

    listBuyTickets(id: number): Observable<ListBuyTicketsResponseDTO> {
        const endpoint = environment.purchased_tickets_event;
        const httpOptions = { headers: this.headers };
        return this.http
            .get<ListBuyTicketsResponseDTO>(`${environment.url_base}/${endpoint}/${id}`, httpOptions)
            .pipe(
                map((result) => {
                    return result;
                })
            );
    }

    addBuyTicket(params: BuyTicketDTO): Observable<SummaryBuyDTO> {
        const endpoint = environment.purchased_tickets;
        const httpOptions = { headers: this.headers };
        return this.http
            .post<SummaryBuyDTO>(`${environment.url_base}/${endpoint}`, JSON.stringify(params), httpOptions)
            .pipe(
                map((result) => {
                    return result;
                })
            );
    }

    getSumaryPayment(id: number): Observable<ResultSumaryPaymentDTO> {
        const endpoint = environment.purchased_tickets;
        const httpOptions = { headers: this.headers };
        return this.http
            .get<ResultSumaryPaymentDTO>(`${environment.url_base}/${endpoint}/${id}`, httpOptions)
            .pipe(
                map((result) => {
                    return result;
                })
            );
    }

    cancelPlace(id: number): Observable<CancelPlaceResponseDTO> {
        const endpoint = environment.purchased_tickets;
        const httpOptions = { headers: this.headers };
        return this.http
            .delete<CancelPlaceResponseDTO>(`${environment.url_base}/${endpoint}/${id}`, httpOptions)
            .pipe(
                map((result) => {
                    return result;
                })
            );
    }

    approvedPayment(params: ApprovePaymentRequestDTO): Observable<ApprovePaymentResponseDTO> {
        const endpoint = environment.approved_payment_endpoint;
        const id = params.id;
        const httpOptions = {
            headers: this.headers
        };
        return this.http.post<ApprovePaymentResponseDTO>(`${environment.url_base
            }/${endpoint}/${id}`, JSON.stringify(params), httpOptions).pipe(map((result) => {
                return result;
            }));
    }

    acceptPayment(params: ApprovePaymentRequestDTO): Observable<ApprovePaymentResponseDTO> {
        const endpoint = environment.accept_payment_endpoint;
        const id = params.id;
        const httpOptions = {
            headers: this.headers
        };
        return this.http.post<ApprovePaymentResponseDTO>(`${environment.url_base
            }/${endpoint}/${id}`, JSON.stringify(params), httpOptions).pipe(map((result) => {
                return result;
            }));
    }

    changePaymentMethod(params: ApprovePaymentRequestDTO): Observable<ApprovePaymentResponseDTO> {
        const endpoint = environment.change_paymentMethod_endpoint;
        const id = params.id;
        const httpOptions = {
            headers: this.headers
        };
        return this.http.put<ApprovePaymentResponseDTO>(`${environment.url_base
            }/${endpoint}/${id}`, JSON.stringify(params), httpOptions).pipe(map((result) => {
                return result;
            }));
    }

    updatePayment(
        request: UpdateTicketRequestDTO
    ): Observable<UpdateResponse> {
        const endpoint = environment.purchased_tickets;
        const id = request.id;
        const httpOptions = { headers: this.headers };
        return this.http
            .put<UpdateResponse>(
                `${environment.url_base}/${endpoint}/${id}`,
                JSON.stringify(request),
                httpOptions
            )
            .pipe(
                map((result) => {
                    return result;
                })
            );
    }

    availablePlaces(request: AvailablePlacesRequestDTO): Observable<AvailablePlacesResponseDTO> {
        const endpoint = environment.available_places_endpoint;
        const id = request.id;
        const params = new HttpParams(
            {
                fromObject: {
                    ticket: request.ticket,
                }
            }
        );
        const httpOptions = { headers: this.headers, params };
        return this.http
            .get<AvailablePlacesResponseDTO>(`${environment.url_base}/${endpoint}/${id}`, httpOptions)
            .pipe(
                map((result) => {
                    return result;
                })
            );
    }

    releaseTickets(request: ReleaseTicketsRequestDTO): Observable<ReleaseTicketsResponseDTO> {
        const endpoint = environment.release_tickets_endpoint;
        const id = request.id;
        const httpOptions = { headers: this.headers };
        return this.http
            .get<ReleaseTicketsResponseDTO>(`${environment.url_base}/${endpoint}/${id}`, httpOptions)
            .pipe(
                map((result) => {
                    return result;
                })
            );
    }

    changePurchasedTicket(request: ChangeTicketsResponseDTO): Observable<any> {
        const endpoint = environment.change_purchased_tickets;
        const httpOptions = { headers: this.headers };
        return this.http
            .post<any>(`${environment.url_base}/${endpoint}`, JSON.stringify(request), httpOptions)
            .pipe(
                map((result) => {
                    return result;
                })
            );
    }

    returnTicket(request: ReturnTicketResponseDTO): Observable<any> {
        const endpoint = environment.return_ticket;
        const httpOptions = { headers: this.headers };
        return this.http
            .post<any>(`${environment.url_base}/${endpoint}`, JSON.stringify(request), httpOptions)
            .pipe(
                map((result) => {
                    return result;
                })
            );
    }
}
