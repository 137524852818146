import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AuthGuard } from './auth/interceptor';
import { UserGuard } from './auth/interceptor/user.guard';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { LayoutComponent } from './shared/components/layout/layout.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
      },
      {
        path: 'home',
        loadChildren: () =>
          import('./home/home.module').then((m) => m.HomeModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'parkingArea',
        loadChildren: () =>
          import('./parking-area/parking-areas/parking-area.module').then(
            (m) => m.ParkingAreaModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'crear-zona-estacionamiento',
        loadChildren: () =>
          import('./parking-area/create-parking-area/create-parking-area.module').then(
            (m) => m.CreateParkingAreaModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'event',
        loadChildren: () =>
          import('./events/components/events.module').then(
            (m) => m.EventsModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'users',
        loadChildren: () =>
          import('./users/users.module').then(
            (m) => m.UsersModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'enclosures',
        loadChildren: () =>
          import('./enclosures/enclosures.module').then(
            (m) => m.EnclosuresModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'create-enclosures',
        loadChildren: () =>
          import('./create-enclosures/create-enclosures.module').then(
            (m) => m.CreateEnclosuresModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'select-enclosure',
        loadChildren: () =>
          import('./select-enclosure/select-enclosure.module').then(
            (m) => m.SelectEnclosureModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'generate-qr',
        loadChildren: () =>
          import('./generate-qr/generate-qr.module').then(
            (m) => m.GenerateQRModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'create-qr',
        loadChildren: () =>
          import('./create-qr/create-qr.module').then(
            (m) => m.CreateQRModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'eventType',
        loadChildren: () =>
          import('./event-type/event-type.module').then(
            (m) => m.EventTypeModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'createEventType',
        loadChildren: () =>
          import('./create-event-type/create-event-type.module').then(
            (m) => m.CreateEventTypeModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'type-prepaid',
        loadChildren: () =>
          import('./prepaid/prepaid.module').then(
            (m) => m.PrepaidModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'historyQR',
        loadChildren: () =>
          import('./history-qrprepaid/history-qrprepaid.module').then(
            (m) => m.HistoryQRPrepaidModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'generateQrToUser',
        loadChildren: () =>
          import('./generate-qrto-user/generate-qrto-user.module').then(
            (m) => m.GenerateQRToUserModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'change-qr-type',
        loadChildren: () =>
          import('./change-qr-type/change-qr-type.module').then(
            (m) => m.ChangeQrTypeModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'prepaid-create',
        loadChildren: () =>
          import('./prepaid-create/prepaid-create.module').then(
            (m) => m.PrepaidCreateModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'sales-user',
        loadChildren: () =>
          import('./sales-users/sales-users.module').then(
            (m) => m.SalesUsersModule
          ),
        canActivate: [UserGuard],
      },
      {
        path: 'sales-user/:enclosure',
        loadChildren: () =>
          import('./sales-users/sales-users.module').then(
            (m) => m.SalesUsersModule
          ),
        canActivate: [UserGuard],
      },
      {
        path: '/:enclosure',
        loadChildren: () =>
          import('./shared/shared.module').then((m) => m.SharedModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'user-tickets',
        loadChildren: () =>
          import('./user-tickets/user-tickets.module').then(
            (m) => m.UserTicketsModule
          ),
        canActivate: [UserGuard],
      },
      {
        path: 'user-prepaid',
        loadChildren: () =>
          import('./user-prepaid/user-prepaid.module').then(
            (m) => m.UserPrepaidModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'user-recharge-prepaid',
        loadChildren: () =>
          import('./user-recharge-prepaid/user-recharge-prepaid.module').then(
            (m) => m.UserRechargePrepaidModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'user-prepaid-history',
        loadChildren: () =>
          import('./user-prepaid-history/user-prepaid-history.module').then(
            (m) => m.UserPrepaidHistoryModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'payment-summary/:id',
        loadChildren: () =>
          import('./payment-summary/payment-summary.module').then(
            (m) => m.PaymentSummaryModule
          ),
        canActivate: [UserGuard],
      },
      {
        path: 'card-payment/:id',
        loadChildren: () =>
          import('./card-payment/card-payment.module').then(
            (m) => m.CardPaymentModule
          ),
        canActivate: [UserGuard],
      },
      {
        path: 'payment-finish',
        loadChildren: () =>
          import('./payment-finish/payment-finish.module').then(
            (m) => m.PaymentFinishModule
          ),
        canActivate: [UserGuard],
      },
      {
        path: 'payment-error',
        loadChildren: () =>
          import('./payment-error/payment-error.module').then(
            (m) => m.PaymentErrorModule
          ),
        canActivate: [UserGuard],
      },
      {
        path: 'payment-pending',
        loadChildren: () =>
          import('./payment-pending/payment-pending.module').then(
            (m) => m.PaymentPendingModule
          ),
        canActivate: [UserGuard],
      },
      {
        path: 'general-report',
        loadChildren: () =>
          import('./general-report/general-report.module').then(
            (m) => m.GeneralReportModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'reports',
        loadChildren: () =>
          import('./reports/reports.module').then(
            (m) => m.ReportsModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'reports-zone-vip',
        loadChildren: () =>
          import('./reports-zone-vip/reports-zone-vip.module').then(
            (m) => m.ReportsZoneVIPModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'prepaid-reports',
        loadChildren: () =>
          import('./prepaid-reports/prepaid-reports.module').then(
            (m) => m.PrepaidReportsModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'prepaid-total-reports',
        loadChildren: () =>
          import('./prepaid-total-reports/prepaid-total-reports.module').then(
            (m) => m.PrepaidTotalReportsModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'prepaid-history-reports',
        loadChildren: () =>
          import('./prepaid-history-reports/prepaid-history-reports.module').then(
            (m) => m.PrepaidHistoryReportsModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'change-pass',
        loadChildren: () =>
          import('./change-pass/change-pass.module').then(
            (m) => m.ChangePassModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'change-profile',
        loadChildren: () =>
          import('./change-profile/change-profile.module').then(
            (m) => m.ChangeProfileModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'scan-qr',
        loadChildren: () =>
          import('./scan-qr/scan-qr.module').then(
            (m) => m.ScanQRModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'discount-code',
        loadChildren: () =>
          import('./discount-code/discount-code.module').then(
            (m) => m.DiscountCodeModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'create-discount-code',
        loadChildren: () =>
          import('./create-discount-code/create-discount-code.module').then(
            (m) => m.CreateDiscountCodeModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'reports-account',
        loadChildren: () =>
          import('./reports-account/reports-account.module').then(
            (m) => m.ReportsAccountModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'reports-infinity',
        loadChildren: () =>
          import('./report-infinity-qr/report-infinity-qr.module').then(
            (m) => m.ReportInfinityQRModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'report-by-month',
        loadChildren: () =>
          import('./report-by-month/report-by-month.module').then(
            (m) => m.ReportByMonthModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'qrabonados',
        loadChildren: () =>
          import('./qrabonados/qrabonados.module').then(
            (m) => m.QRAbonadosModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'report-by-closing-month',
        loadChildren: () =>
          import('./report-by-closing-month/report-by-closing-month.module').then(
            (m) => m.ReportByClosingMonthModule
          ),
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: '',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: '',
    loadChildren: () =>
      import('./shared/shared.module').then((m) => m.SharedModule),
    canActivate: [AuthGuard],
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
