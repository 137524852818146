import { Observable } from 'rxjs';
import {
    ListarQRCortesiaRequestDTO,
    ListarQRCortesiaResponseDTO,
    GenerarQRCortesiaRequestDTO,
    GenerarQRCortesiaResponseDTO,
    AsignarQRCortesiaRequestDTO,
    AsignarQRCortesiaResponseDTO,
    ReportsRequestDTO,
    DownloadExcelRequestDTO,
    DownloadExcelDTO,
    LeerQrCortesiaRequestDTO,
    LeerQRCortesiaDTO,
    RegistrarQRCortesiaRequestDTO,
    RegistrarQRCortesiaResponseDTO,
    DeleteQRCortesiaRequestDTO,
    DeleteQRCortesiaResponseDTO,
    ListQRInfinityRequestDTO,
    ListQRInfinityResponseDTO,
    RescheduleQRRequestDTO,
    RescheduleQRResponseDTO,
} from 'src/app/data/dto/QRCortesiaDTO';

export abstract class QRCortesiaRepository {
    abstract listarQRCortesia(request: ListarQRCortesiaRequestDTO): Observable<ListarQRCortesiaResponseDTO>;
    abstract generarQRCortesia(request: GenerarQRCortesiaRequestDTO): Observable<GenerarQRCortesiaResponseDTO>;
    abstract asignarQRCortesia(request: AsignarQRCortesiaRequestDTO): Observable<AsignarQRCortesiaResponseDTO>;
    abstract reporteQRCortesia(request: ReportsRequestDTO): Observable<ListarQRCortesiaResponseDTO>;
    abstract DownloadExcel(file: DownloadExcelRequestDTO): Observable<DownloadExcelDTO>;
    abstract lecturaQR(id: LeerQrCortesiaRequestDTO): Observable<LeerQRCortesiaDTO>;
    abstract registrarQR(qr: RegistrarQRCortesiaRequestDTO): Observable<RegistrarQRCortesiaResponseDTO>;
    abstract deleteQR(id: DeleteQRCortesiaRequestDTO): Observable<DeleteQRCortesiaResponseDTO>;
    abstract reportInfinityQR(request: ListQRInfinityRequestDTO): Observable<ListQRInfinityResponseDTO>;
    abstract rescheduleQR(request: RescheduleQRRequestDTO): Observable<RescheduleQRResponseDTO>;
}
