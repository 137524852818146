<footer class="footer">
  <div class="row align-items-center justify-content-xl-between without-margin-right without-margin-left">
    <div class="col-xl-6">
      <div class="copyright text-center text-xl-left text-muted">
        &copy; {{ test | date: "yyyy" }} <a href="https://www.parkit.mx/" class="login-link font-weight-bold ml-1"
          target="_blank">Parkit</a>
      </div>
    </div>
    <div class="col-xl-6">
      <ul class="nav nav-footer justify-content-center justify-content-xl-end">
        <li class="nav-item">
          <a class=" login-link nav-link" target="_blank" (click)="onModal()">Todos los derechos reservados </a>
        </li>
      </ul>
    </div>
  </div>
</footer>

<ng-template #modalPayment let-c="close" let-d="dismiss">
  <div class="modal-header text-center">
    TERMINOS Y CONDICIONES
  </div>
  <div class="modal-body">
    Por favor lea cuidadosamente la siguiente información. Su contenido fue redactado con un lenguaje amigable para
    facilitar su entendimiento, no obstante, encontrará terminología legal la cual es necesaria. Los Términos y
    Condiciones (en lo sucesivo denominados como “Acuerdo”) constituyen un acuerdo legal entre USTED y PARKIT.
  <br>
  <br>
    Al acceder o utilizar nuestros servicios, nuestras páginas asociadas, aplicaciones móviles, o cualquier servicio
    distribuido o vendido a través de éstas (en lo sucesivo denominado los “Servicios”), USTED declara que:
  <br>
    · Ha leído, entendido y aceptado que quedará vinculado por el presente Acuerdo y a cualquier modificación futura
    de éste, las cuales son publicadas en <a href="https://www.parkit.mx/" class="login-link font-weight-bold ml-1"
      target="_blank">https://www.parkit.mx</a> o a través de cualquiera de los Servicios.
  <br>
    · Es mayor de edad en la jurisdicción en la que reside para formar un Acuerdo con Parkit.
  <br>
    · Tiene la autoridad de entrar en el Acuerdo personalmente o en nombre de la empresa que haya nombrado como el
    Usuario de los servicios, así como de obligar a la empresa a que cumpla el Acuerdo.
    Salvo que se disponga otra cosa, si no está de acuerdo en obligarse por el Acuerdo, no podrá acceder o utilizar
    los Servicios. Parkit se reserva el derecho a modificar los términos y condiciones de este Acuerdo en cualquier
    momento y sin previo aviso. Cualquier disputa o reclamación relacionada los Servicios, será regido e interpretado
    por y bajo las leyes del Estado de Nuevo León, en México, sin dar efecto a cualquier otra jurisdicción.
    <br>
    <br>
    <br>
    Generales
    <br>
    Los Organizadores operan de forma independiente. Parkit no es el organizador ni el propietario de los eventos,
    sino que actúa como ente independiente del Organizador con el único fin de ofrecer Servicios. El Organizador es el
    único responsable de asegurarse de que la naturaleza y conducta del evento subyacente cumplan con todas las leyes,
    reglas y normativas locales, estatales, nacionales y de cualquier otro tipo, y de que los bienes y servicios
    descritos en la página del evento se proporcionen de manera satisfactoria.
    Parkit no tiene responsabilidad alguna por los actos de todo Organizador, aparte de las establecidas en el
    presente documento.
    <br>
    <br>
    <br>
    Condiciones de pago
    <br>
    La compra de boletos de estacionamiento a través de los Servicios generará un cargo por servicio en adición al
    precio de cada boleto.
    <br>
    Parkit puede sin previo aviso, cambiar el monto de los cargos por concepto de comisiones por los Servicios que
    considere necesarios o apropiados para su negocio.
    <br>
    Parkit utiliza diferentes procesadores de pago para el cobro de los Servicios. Los métodos de pago pueden ser, sin
    limitarse a éstos, pago con Tarjeta o Depósitos en Efectivo
    <br>
    Las comisiones pueden ser diferentes para cada método de pago.
    <br>
    En el caso de pago con Tarjeta, la finalización de la compra de boletos a través los Servicios está sujeto a la
    comprobación de los datos personales y bancarios proporcionados por el Usuario y a la autorización por parte del
    banco emisor de la Tarjeta. Si los datos personales o de la Tarjeta proporcionados no coinciden con los datos a
    disposición del banco o, aun coincidiendo los datos en cuestión, el banco decide no autorizar el cargo
    solicitado por el Usuario, la compra no será procesada ni finalizada y los boletos o servicios serán ofrecidos
    para venta al público sin responsabilidad alguna para Parkit.
    <br>
    Adicionalmente, Parkit, a discreción, podrá contactar al Usuario para solicitar información adicional relacionado
    a su compra e identidad, con el fin de corroborar la veracidad de los datos proporcionados y dar por procesada la
    compra.
    <br>
    Sin perjuicio de lo anterior, USTED expresamente reconoce y acepta que la solicitud de compra de los boletos de
    estacionamiento que realiza a través de los Servicios es una oferta vinculante, y que una vez recibida la
    autorización del banco emisor de la Tarjeta o del banco aceptante, la operación de compra será definitiva y no
    estará sujeta a cambios, reembolsos, devoluciones o cancelaciones.
    <br>
    En el caso de pago con Depósitos en Efectivo, Parkit le otorgará un límite de tiempo para completar su compra,
    junto con información sobre el número de cuenta a depositar, referencia y/o otros datos. La cantidad depositada
    debe ser exactamente igual a la descrita en su orden de pago. Una vez realizado el pago, sus boletos serán
    expedidos y enviados a su correo electrónico en un lapso no mayor a 7 horas.
    <br>
    Si USTED realiza un pago fuera del tiempo establecido por Parkit, o por una cantidad distinta a la denotada en su
    orden de pago, sus boletos no serán expedidos y/o generados.
    <br>
    USTED expresamente reconoce y acepta que Parkit no es responsable de los errores (conscientes o inconscientes) que
    USTED realice al momento de realizar un Depósitos en Efectivo, y que incluyen, sin limitarse a éstos, pago fuera
    de tiempo o una cantidad diferente a la descrita en su orden de pago, y que Parkit no está obligado a entregarle
    los boletos correspondientes a su compra, ni a realizarle un reembolso.
    <br>
    Como un acto de buena fe, Parkit intentará por todos los medios de rastrear la cantidad depositada erróneamente
    por el Usuario, y en la medida de lo posible reembolsar al Usuario su pago equivocado. Las cuentas y/o referencias
    para los pagos de eventos son cuentas especiales creadas para este tipo de pagos, por lo que Parkit no
    necesariamente tiene acceso al dinero depositado por los Usuarios cuando cometieron un error, ni acceso a la
    información de la cuenta destino. Este acto de buena fe no corresponde de ninguna manera una responsabilidad,
    obligación o acuerdo que deba ser cumplido por Parkit.
    <br>
    <br>
    <br>
    Derechos y responsabilidades
    <br>
    USTED expresamente reconoce y acepta que no tendrá derecho a cambios, reembolsos, devoluciones o cancelaciones aún
    en el supuesto de que elimine, no imprima o no haga uso de los boletos, productos o servicios adquiridos a través
    de los Servicios.
    <br>
    Del mismo modo, USTED expresamente reconoce y acepta que sólo tendría derecho a un reembolso del costo de los
    boletos de estacionamiento en caso de que se cancele el evento para el cual adquirió los boletos o servicios, en
    el entendido de que en ese supuesto, USTED no tendrá derecho a que se le reembolsen los cargos por los Servicios,
    es decir, se reembolsará el precio base de los boletos, y éste reembolso correrá a cargo del Organizador, a menos
    que Parkit le notifique por escrito lo contrario. El reembolso por parte del Organizador también depende de sus
    Términos y Servicios y deberá ser revisado de forma independiente a este Acuerdo entre el Usuario y el
    Organizador.
    <br>
    USTED exime de cualquier responsabilidad a Parkit en el caso de que se cancele el evento para el cual adquirió los
    boletos o servicios. La responsabilidad única en este supuesto recae en el Organizador.
    <br>
    Parkit no tiene responsabilidad alguna por los actos de todo Organizador, aparte de las establecidas en el
    presente documento.
    <br>
    El número de boletos adquiribles por evento para un mismo usuario está limitado. Si en virtud de una o más
    operaciones de compra se excede el límite de boletos adquiribles por un mismo Usuario, las solicitudes de compra
    serán canceladas sin previo aviso y sin responsabilidad para Parkit.
    <br>
    <br>
    <br>
    Cuenta de usuario
    <br>
    USTED acepta que es el único Usuario autorizado sobre cualquier cuenta que cree en los Servicios. Adicionalmente,
    Usted es el único y total responsable de todas las actividades que ocurran bajo su contraseña o cuenta.
    <br>
    USTED acepta que es el responsable de monitorear su cuenta con respecto a su uso por parte de menores, y acepta
    toda la responsabilidad por cualquier uso no autorizado de su contraseña o su cuenta por parte de terceros o
    menores.
    <br>
    USTED no puede ceder o transferir su cuenta de Usuario a ninguna otra persona o entidad. Parkit no tiene control
    sobre el uso de la cuenta de un Usuario y renuncia expresamente a cualquier responsabilidad derivada de dicha
    utilización. En caso de que sospeche que alguna parte no autorizada pueda estar utilizando su contraseña o cuenta,
    deberá notificar inmediatamente a Parkit. Si usted proporciona alguna información que sea falsa, inexacta,
    desactualizada o incompleta, o si Parkit tiene motivos razonables para sospechar que dicha información es falsa,
    inexacta, desactualizada o incompleta, Parkit tiene el derecho de suspender o terminar su cuenta y negarle el uso
    presente o futuro de los Servicios (o cualquier parte del mismo). USTED se compromete a no crear una cuenta o
    utilizar los Servicios si usted ha sido eliminado previamente por Parkit, o si ha sido expulsado previamente de
    uso de los Servicios.
    <br>
    <br>
    <br>
    Garantías
    <br>
    Al utilizar los Servicios, USTED expresamente garantiza que está legalmente autorizado para entrar en este
    Acuerdo. El uso de los Servicios es para su uso único y personal, o de la empresa que representa.
    <br>
    Al utilizar los Servicios, USTED acepta cumplir con todas las leyes aplicables en su país de origen y el país,
    estado y ciudad en los que está presente durante el uso de los Servicios.
    <br>
    Sólo se puede acceder a los Servicios utilizando medios autorizados. Es su responsabilidad verificar que ha
    ingresado a los Servicios de forma correcta. Parkit no es responsable si USTED ingreso a un portal equivocado, no
    tiene un dispositivo compatible o si descargó la versión incorrecta de los Servicios para su dispositivo. Parkit
    se reserva el derecho de terminar este Acuerdo en caso de que usted esté utilizando los Servicios con un
    dispositivo incompatible o no autorizado.
    <br>
    USTED solo utilizará los Servicios con fines lícitos; no utilizará los Servicios para enviar o almacenar cualquier
    material ilegal o con fines fraudulentos o engañosos.
    USTED no utilizará los Servicios con el fin de causar daños, inconvenientes y molestias a otras personas o
    empresas.
    <br>
    USTED no utilizará los Servicios con fines comerciales a menos que Parkit le haya otorgado autorización expresa y
    por escrito para hacerlo.
    <br>
    USTED no copiará o distribuirá el software u otro contenido de los Servicios sin autorización por escrito de
    Parkit.
    <br>
    USTED no creará o compilará, directa o indirectamente, una colección, compilación, u otro directorio del contenido
    de los Servicios.
    <br>
    USTED manifiesta que los datos personales y de contacto que usted proporciona al momento de registrarse o de algún
    otro modo debe ser certera y exacta, debiendo notificar a Parkit sobre cualquier cambio en dicha información, para
    lo cual es posible que le solicitemos una prueba de identidad.
    <br>
    USTED está consciente de que al solicitar servicios por internet, se aplicarán cargos estándar de consumo de datos
    de su compañía proveedora de internet.
    <br>
    Es responsabilidad de USTED, mantener su contraseña o cualquier forma de acceso a los Servicios de forma segura y
    confidencial.
    <br>
    USTED sólo utilizará los Servicios para su propio uso y no para revender los Servicios a un tercero.
    <br>
    USTED no utilizará los Servicios en cualquier forma que pueda dañar, inutilizar, sobrecargar o deteriorar
    cualquier servidor de Parkit, o las redes conectadas a cualquier servidor de Parkit.
    <br>
    USTED no intentará obtener acceso no autorizado a cualquier parte los Servicios, cuenta, sistema informático y/o
    red conectada a un servidor de Parkit.
    <br>
    USTED no utilizará ningún software o técnica especializada para copiar o monitorear cualquier parte o contenido de
    los Servicios, a menos que Parkit haya dado permiso para hacerlo por escrito. Estas técnicas incluyen sin estar
    limitadas a deeplink, robots, spiders, web crawler, software extraction, y/o procesos manuales o automatizados de
    scrapping.
    <br>
    USTED no podrá copiar cualquier contenido de los Servicios.
    <br>
    USTED no intentará dañar los Servicios en modo alguno.
    <br>
    <br>
    <br>
    Exclusión de garantías
    <br>
    USTED entiende y acepta que el uso de los Servicios es bajo su propio riesgo. Cambios periódicos en los Servicios
    pueden ser realizados en cualquier momento sin previo aviso. Los Servicios se proporcionan “tal cual” sin
    garantías de ningún tipo, expresa o implícita, incluyendo, pero no limitado a las garantías de comercialización.
    <br>
    Parkit no ofrece garantías o representación sobre la exactitud, fiabilidad, integridad u oportunidad del contenido
    disponible a través los Servicios.
    <br>
    Parkit no garantiza que los Servicios funcionarán sin errores, o que estén libres de virus informáticos y otros
    paquetes de software dañinos. Si el uso de los Servicios resultara en la necesidad de un servicio de reparación o
    reemplazo de equipos o de datos, Parkit no será responsable de estos gastos económicos.
    <br>
    <br>
    <br>
    Limitación de responsabilidad
    <br>
    Límite Máximo de Responsabilidad: La responsabilidad máxima total de la empresa ante una reclamación no podrá
    exceder nunca la máxima de las siguientes: (a) la cantidad pagada por USTED al utilizar los Servicios, y (b) la
    sanción impuesta por el estatuto en que surge dicha reclamación.
    <br>
    Exclusión de ciertos daños: Parkit no será responsable de cualquier daño indirecto y/o incidental o daños de
    cualquier otro tipo o clase a su automóvil (incluyendo lesiones personales, pérdida de datos, ingresos, ganancias,
    uso o otras ventajas económicas) que USTED pueda incurrir por el uso de los Servicios o por atender a algún evento
    donde operen nuestros Servicios.
    <br>
    <br>
    <br>
    Participación en disputas
    <br>
    USTED acepta no participar en cualquier caso presente o futuro que se pueda ser presentado contra Parkit.
    <br>
    <br>
    <br>
    Ubicación
    <br>
    Todas las reclamaciones y disputas que surjan del uso o relativas al Acuerdo serán litigadas exclusivamente en los
    tribunales estatales o federales ubicados en Monterrey, Nuevo León, México.
    <br>
    <br>
    <br>
    Terminación
    <br>
    A su discreción, Parkit puede modificar, suspender o interrumpir el acceso a los Servicios, por cualquier razón,
    con o sin previo aviso y sin ninguna responsabilidad hacia USTED o cualquier tercero. Sin embargo, incluso después
    de que su derecho a utilizar los Servicios se termine, este Acuerdo se mantendrá en vigor para los efectos que así
    lo requieran.
    <br>
    <br>
    <br>
    Otros
    <br>
    Este Acuerdo se rige por las leyes de México.
    <br>
    No existe empresa conjunta, relación de empleo o de agencia entre USTED, Parkit o cualquier Organizador como
    resultado de este Acuerdo o uso de los Servicios.
    Salvo que se disponga de otra manera, si alguna disposición de este Acuerdo se considera inválida, la invalidez de
    dicha disposición no afectará a la validez de las disposiciones restantes de este acuerdo, las cuales permanecerán
    en pleno vigor y efecto.
    <br>
    Cuando Parkit requiere que se proporcione una dirección de correo electrónico, USTED es responsable de
    proporcionar a Parkit su dirección de correo electrónico más actual. En el caso de que la última dirección de
    correo electrónico que ha proporcionado a Parkit no sea válida, o por cualquier razón no es capaz de ofrecer a
    USTED los avisos requeridos o permitidos por el presente Acuerdo, el envío de Parkit del correo electrónico con
    dicha notificación constituirá una notificación efectiva para efectos legales. Dicha notificación se considerará
    entregada cuando Parkit envíe dicho correo electrónico.
    <br>
    A efectos contractuales, USTED otorga el consentimiento para recibir comunicaciones de Parkit en forma
    electrónica.
    <br>
    Este Acuerdo es el acuerdo final, completo y exclusivo entre USTED y Parkit y sustituye y fusiona todas las
    discusiones previas con respecto a dicho asunto.
    <br>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-green w-100" [disabled]="loadingHire" (click)="onNext()">
      <span *ngIf="loadingHire" class="spinner-border spinner-border-sm mr-1"></span>
      Cerrar
    </button>
  </div>
</ng-template>
