import { Observable } from 'rxjs';
import {
    EventsRequestDTO,
    AddEventDTO,
    DeleteEventRequestDTO,
    DeleteEventResponseDTO,
    EditEventResponseDTO,
    EventResponseEditDTO,
    EventsAfterAddResponseDTO,
    EventsByIdResponseDTO,
    EventsResponseDTO,
    EventsWithZoneResponseDTO,
    EventZoneDTO,
    MapResponseDTO,
    ZonasRequestDTO,
    ZonasResponseDTO,
    CardRequestDTO,
    CardResponseDTO,
    CardParcheRequestDTO,
    CardParcheResponseDTO,
    CardParcheV2RequestDTO,
    CardParcheV2ResponseDTO,
    QrRequestDTO,
    QrResponseDTO,
    DetalleZonaRequestDTO,
    DetalleZonaDTO,
    ReportsRequestDTO,
    ReportsDTO,
    QRDTO,
    RegistrarQRRequestDTO,
    RegistrarQRResponseDTO,
    ConfirmacionRequestDTO,
    ConfirmacionResponseDTO,
    DownloadExcelRequestDTO,
    DownloadExcelDTO,
    CardParche2V2RequestDTO,
    MPRequestDTO,
    MPResponseDTO,
    ChangeStatusDTO,
    ChangeStatusResponseDTO,
    PendingPaymentMercadoPago,
    ResponsePendingPaymentMercadoPago,
    PaymentUpgrade,
    PaymentUpgradeResponse,
    GeneralReportDTO,
    GeneralReportRequestDTO,
    OnlyEventsDTO,
    OnlyInfinityEventsResponseDTO,
    ReportByMonthRequestDTO,
    ReportByMonthResponseDTO,
    EventByMonthRequestDTO,
    EventByMonthResponseDTO,
    EventsFiltersRequestDTO,
    RescheduleEventRequestDTO,
    RescheduleEventResponseDTO,
} from 'src/app/data/dto/EventDTO';

export abstract class EventRepository {
    abstract listEvents(request: EventsRequestDTO): Observable<EventsResponseDTO>;
    abstract addEvent(request: AddEventDTO): Observable<EventsAfterAddResponseDTO>;
    abstract deleteEvent(id: DeleteEventRequestDTO): Observable<DeleteEventResponseDTO>;
    abstract eventWithAreas(id: EventZoneDTO): Observable<EventsWithZoneResponseDTO>;
    abstract mapOfEvent(id: number): Observable<MapResponseDTO>;
    abstract updateEvent(params: EditEventResponseDTO): Observable<EventResponseEditDTO>;
    abstract getEventById(id: number): Observable<EventsByIdResponseDTO>;
    abstract getZonasById(id: ZonasRequestDTO): Observable<ZonasResponseDTO>;
    abstract payEventById(id: CardRequestDTO): Observable<CardResponseDTO>;
    abstract payEventParcheById(id: CardParcheRequestDTO): Observable<CardParcheResponseDTO>;
    abstract payEventParcheV2ById(id: CardParcheV2RequestDTO): Observable<CardParcheV2ResponseDTO>;
    abstract QRById(id: QrRequestDTO): Observable<QrResponseDTO>;
    abstract zonasById(id: DetalleZonaRequestDTO): Observable<DetalleZonaDTO>;
    abstract reports(request: ReportsRequestDTO): Observable<ReportsDTO>;
    abstract lecturaQR(id: QrRequestDTO): Observable<QRDTO>;
    abstract registrarQR(qr: RegistrarQRRequestDTO): Observable<RegistrarQRResponseDTO>;
    abstract confirmacionPayment(referencia: ConfirmacionRequestDTO): Observable<ConfirmacionResponseDTO>;
    abstract DownloadExcel(file: DownloadExcelRequestDTO): Observable<DownloadExcelDTO>;
    abstract payEventParche2V2ById(id: CardParche2V2RequestDTO): Observable<CardParcheV2ResponseDTO>;
    abstract payMercadoPago(id: MPRequestDTO): Observable<MPResponseDTO>;
    abstract changeStatusEvent(id: ChangeStatusDTO): Observable<ChangeStatusResponseDTO>;
    abstract payPendingMercadoPago(id: PendingPaymentMercadoPago): Observable<ResponsePendingPaymentMercadoPago>;
    abstract paymentUpgradeMercadoPago(params: PaymentUpgrade): Observable<PaymentUpgradeResponse>;
    abstract filterEvent(request: EventsFiltersRequestDTO): Observable<EventsResponseDTO>;
    abstract generalReport(request: GeneralReportRequestDTO): Observable<GeneralReportDTO>;
    abstract getOnlyEvents(id: number): Observable<OnlyEventsDTO>;
    abstract listInfinityEvents(): Observable<OnlyInfinityEventsResponseDTO>;
    abstract reportByMonth(request: ReportByMonthRequestDTO): Observable<ReportByMonthResponseDTO>;
    abstract eventsByMonth(request: EventByMonthRequestDTO): Observable<EventByMonthResponseDTO>;
    abstract rescheduleEvent(request: RescheduleEventRequestDTO): Observable<RescheduleEventResponseDTO>;
}
