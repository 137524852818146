import { Observable } from 'rxjs';
import {
    GetAllTypeOfPrepaidRequestDTO,
    GetAllTypeOfPrepaidResponseDTO,
    RequestDTO,
    TypesPrepaidDTO,
    ListarTypesPrepaidRequestDTO,
    ListarTypesPrepaidResponseDTO,
    DeleteTypePrepaidRequestDTO,
    UpdateQRPrepaidRequestDTO,
    UpdateQRPrepaidResponseDTO,
    ReportsRequestDTO,
    ReportsTotalRequestDTO,
    ReportsHistoryRequestDTO,
    CardRequestDTO,
    CardResponseDTO,
    CardParcheV2RequestDTO,
    CardParcheV2ResponseDTO,
    CardParche2V2RequestDTO,
    Referenciaprepago,
    RechargeBalanceRequestDTO,
    AddParkingPenDTO,
    ReportsAccountRequestDTO,
    ReportsAccountResponseDTO,
    ReportsClosingMonthRequestDTO,
    ReportsClosingMonthResponseDTO,
    CheckUserSyncRequest,
    CheckUserSyncResponse,
    SyncUserResponse,
    SyncUserRequest
} from 'src/app/data/dto/QRPrepaidDTO';

export abstract class QRPrepagoRepository {
    abstract listarQRPrepago(request: any): Observable<any>;
    abstract generarQRPrepago(request: any): Observable<any>;
    abstract hitorialQRPrepago(request: any): Observable<any>;
    abstract accountStatus(request: any): Observable<any>;
    abstract listTypes(request: ListarTypesPrepaidRequestDTO): Observable<ListarTypesPrepaidResponseDTO>;
    abstract updateTypePrepaid(request: TypesPrepaidDTO): Observable<ListarTypesPrepaidResponseDTO>;
    abstract createTypePrepaid(request: RequestDTO): Observable<TypesPrepaidDTO>;
    abstract deleteType(request: DeleteTypePrepaidRequestDTO): Observable<ListarTypesPrepaidResponseDTO>;
    abstract getAllTypeOfPrepaid(request: GetAllTypeOfPrepaidRequestDTO): Observable<GetAllTypeOfPrepaidResponseDTO>;
    abstract updateTypeOfPrepaid(request: UpdateQRPrepaidRequestDTO): Observable<UpdateQRPrepaidResponseDTO>;
    abstract reports(request: ReportsRequestDTO): Observable<any>;
    abstract reportsTotal(request: ReportsTotalRequestDTO): Observable<any>;
    abstract reportsHistory(request: ReportsHistoryRequestDTO): Observable<any>;

    abstract payEventById(request: CardRequestDTO): Observable<CardResponseDTO>;
    abstract payEventParcheV2ById(request: CardParcheV2RequestDTO): Observable<CardParcheV2ResponseDTO>;
    abstract payEventParche2V2ById(request: CardParche2V2RequestDTO): Observable<CardParcheV2ResponseDTO>;
    abstract generaReferenciaPago(request: Referenciaprepago): Observable<any>;
    abstract migrateUser(request: { email: string }): Observable<any>;
    abstract rechargeNewBalance(request: RechargeBalanceRequestDTO): Observable<any>;
    abstract registerUserByAdminInPen(request: AddParkingPenDTO): Observable<any>;

    abstract getReportStatus(request: ReportsAccountRequestDTO): Observable<ReportsAccountResponseDTO>;
    abstract closingMonthReport(request: ReportsClosingMonthRequestDTO): Observable<ReportsClosingMonthResponseDTO>;
    abstract checkUserSync(request: CheckUserSyncRequest): Observable<CheckUserSyncResponse>;
    abstract syncUserInBarrier(request: SyncUserRequest): Observable<SyncUserResponse>;
}
