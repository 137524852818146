import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AuthService } from 'src/app/auth/service/auth.service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { EnclosuresRepository } from 'src/app/core/repositories/enclosures/EnclosuresRepository';
import { map } from 'rxjs/operators';
import {
    RecintosRequestDTO,
    RecintosResponseDTO,
    EnclosuresRequestDTO,
    EnclosuresResponseDTO,
    UpdateEnclosuresRequestDTO,
    UpdateEnclosuresResponseDTO,
    DeleteEnclosuresRequestDTO,
    DeleteEnclosuresResponseDTO,
    EventsbyvenueRequestDTO,
    EventsbyvenueResponseDTO,
    ZoneByEventsRequestDTO,
    ZoneByEventsResponseDTO,
    EventsByMonthRequestDTO,
    EventsByMonthResponseDTO,
    OnlyEnclosureDTO,
    OnlyEnclosure,
    OnlyEnclosureByAdmin,
} from '../../dto/EnclosuresDTO';

@Injectable({
    providedIn: 'root',
})

export class EnclosuresRestRepository extends EnclosuresRepository {
    private headers: HttpHeaders;
    constructor(private http: HttpClient, private auth: AuthService) {
        super();
        this.headers = new HttpHeaders({
            'Content-Type': 'application/json',
        });
    }

    enclosures(
      request: RecintosRequestDTO
    ): Observable<RecintosResponseDTO> {
      const endpoint = environment.enclosure_endpoint;
      const params = new HttpParams(
        {
            fromObject: {
                admin: request.admin,
            }
        }
      );
      const httpOptions = {
        headers: this.headers ,
        params
      };
      return this.http
        .get<RecintosResponseDTO>(
          `${environment.url_base}/${endpoint}`,
          httpOptions
        )
        .pipe(
          map((result) => {
            return result;
          })
        );
    }

    createEnclosures(
      request: EnclosuresRequestDTO
    ): Observable<EnclosuresResponseDTO> {
      const endpoint = environment.enclosure_endpoint;
      const httpOptions = { headers: this.headers };
      return this.http
        .post<EnclosuresResponseDTO>(
          `${environment.url_base}/${endpoint}`,
          request,
          httpOptions
        )
        .pipe(
          map((result) => {
            return result;
          })
        );
    }

    updateEnclosures(
      request: UpdateEnclosuresRequestDTO
    ): Observable<UpdateEnclosuresResponseDTO> {
      const endpoint = environment.enclosure_endpoint;
      const id = request.id;
      const httpOptions = { headers: this.headers };
      return this.http
          .put<UpdateEnclosuresResponseDTO>(
            `${environment.url_base}/${endpoint}/${id}`,
            JSON.stringify(request),
            httpOptions
          )
          .pipe(
              map((result) => {
                  return result;
              })
          );
    }

    deleteEnclosures(
      request: DeleteEnclosuresRequestDTO
    ): Observable<DeleteEnclosuresResponseDTO> {
      const endpoint = environment.enclosure_endpoint;
      const id = request.id;
      const httpOptions = { headers: this.headers };
      return this.http
        .delete<DeleteEnclosuresResponseDTO>(
          `${environment.url_base}/${endpoint}/${id}`,
          httpOptions
        )
        .pipe(
          map((result) => {
            return result;
          })
        );
    }

    eventsByEnclosures(
      request: EventsbyvenueRequestDTO
    ): Observable<EventsbyvenueResponseDTO> {
      const endpoint = environment.eventos_por_recintos_endpoint;
      const id = request.id;
      const httpOptions = { headers: this.headers };
      return this.http
          .get<EventsbyvenueResponseDTO>(
            `${environment.url_base}/${endpoint}/${id}`,
            httpOptions
          )
          .pipe(
              map((result) => {
                  return result;
              })
          );
    }

    eventsByMonthByEnclosures(
      request: EventsByMonthRequestDTO
    ): Observable<EventsByMonthResponseDTO> {
      const endpoint = environment.eventos_por_mes_por_recintos_endpoint;
      const id = request.id;
      const httpOptions = { headers: this.headers };
      return this.http
          .get<EventsByMonthResponseDTO>(
            `${environment.url_base}/${endpoint}/${id}`,
            httpOptions
          )
          .pipe(
              map((result) => {
                  return result;
              })
          );
    }

    zoneByEvents(
      request: ZoneByEventsRequestDTO
    ): Observable<ZoneByEventsResponseDTO> {
      const endpoint = environment.event_areas_endpoint;
      const id = request.id;
      const httpOptions = { headers: this.headers };
      return this.http
          .get<ZoneByEventsResponseDTO>(
            `${environment.url_base}/${endpoint}/${id}`,
            httpOptions
          )
          .pipe(
              map((result) => {
                  return result;
              })
          );
    }

    onlyEnclosures(): Observable<OnlyEnclosureDTO> {
      const endpoint = environment.only_enclosures_endpoint;
      const httpOptions = {
        headers: this.headers
      };
      return this.http
        .get<OnlyEnclosureDTO>(
          `${environment.url_base}/${endpoint}`,
          httpOptions
        )
        .pipe(
          map((result) => {
            return result;
          })
        );
    }

    onlyEnclosure(): Observable<OnlyEnclosure> {
      const endpoint = environment.only_enclosure_endpoint;
      const httpOptions = { headers: this.headers };
      return this.http
          .get<OnlyEnclosure>(
            `${environment.url_base}/${endpoint}`,
            httpOptions
          )
          .pipe(
              map((result) => {
                  return result;
              })
          );
    }

    enclosureByAdmin(
      request: OnlyEnclosureByAdmin
    ): Observable<OnlyEnclosure> {
      const endpoint = environment.only_enclosure_byAdmin_endpoint;
      const id = request.idAdmin;
      const httpOptions = { headers: this.headers };
      return this.http
          .get<OnlyEnclosure>(
            `${environment.url_base}/${endpoint}/${id}`,
            httpOptions
          )
          .pipe(
              map((result) => {
                  return result;
              })
          );
    }

}
