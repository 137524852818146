import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from 'src/app/auth/service/auth.service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { CartRepository } from 'src/app/core/repositories/cart/CartRepository';
import { map } from 'rxjs/operators';
import {
    CartRequestDTO,
    CartResponse,
    Cart,
    CartResponseDTO,
    DeleteCartRequestDTO,
    DeleteCartResponseDTO,
    EmptyCartRequestDTO,
    EmptyCartResponseDTO,
    PayCartRequestDTO,
    PayCartResponseDTO,
    UpdateCartRequestDTO,
    UpdateCartResponseDTO,
} from 'src/app/data/dto/CartDTO';

@Injectable({
    providedIn: 'root',
})

export class CartRestRepository extends CartRepository {
    private headers: HttpHeaders;
    constructor(private http: HttpClient, private auth: AuthService) {
        super();
        this.headers = new HttpHeaders({
            'Content-Type': 'application/json',
        });
    }

    listCart(request: Cart): Observable<CartResponseDTO> {
        const id = request.id;
        const endpoint = environment.cart.get_cart;
        const httpOptions = { headers: this.headers };
        return this.http
            .get<CartResponseDTO>(
                `${environment.url_base}/${endpoint}/${id}`,
                httpOptions
            )
            .pipe(
                map((result) => {
                    return result;
                })
            );
    }

    createCart(
        request: CartRequestDTO
    ): Observable<CartResponse> {
        const id = request.intFKIdUser;
        const endpoint = environment.cart.base;
        const httpOptions = { headers: this.headers };
        return this.http
            .post<CartResponse>(
                `${environment.url_base}/${endpoint}/${id}`,
                request,
                httpOptions
            )
            .pipe(
                map((result) => {
                    return result;
                })
            );
    }

    getMyCart(request: Cart): Observable<CartResponseDTO> {
        const id = request.id;
        const endpoint = environment.cart.get_my_cart;
        const httpOptions = { headers: this.headers };
        return this.http
            .get<CartResponseDTO>(
                `${environment.url_base}/${endpoint}/${id}`,
                httpOptions
            )
            .pipe(
                map((result) => {
                    return result;
                })
            );
    }

    payMyCart(
        request: PayCartRequestDTO
    ): Observable<PayCartResponseDTO> {
        const id = request.idCart;
        const endpoint = environment.cart.pay_my_cart;
        const httpOptions = { headers: this.headers };
        return this.http
            .put<PayCartResponseDTO>(
                `${environment.url_base}/${endpoint}/${id}`,
                request,
                httpOptions,
            )
            .pipe(
                map((result) => {
                    return result;
                })
            );
    }

    emptyCart(
        request: EmptyCartRequestDTO
    ): Observable<EmptyCartResponseDTO> {
        const endpoint = environment.cart.empty_cart;
        const id = request.idTicket;
        const httpOptions = { headers: this.headers };
        return this.http
            .delete<EmptyCartResponseDTO>(
                `${environment.url_base}/${endpoint}/${id}`,
                httpOptions
            )
            .pipe(
                map((result) => {
                    return result;
                })
            );
    }

    deleteCart(
        request: DeleteCartRequestDTO
    ): Observable<DeleteCartResponseDTO> {
        const endpoint = environment.cart.delete_cart;
        const id = request.idUser;
        const httpOptions = { headers: this.headers };
        return this.http
            .post<DeleteCartResponseDTO>(
                `${environment.url_base}/${endpoint}/${id}`,
                request,
                httpOptions
            )
            .pipe(
                map((result) => {
                    return result;
                })
            );
    }

    updateMyCart(
        request: UpdateCartRequestDTO
    ): Observable<UpdateCartResponseDTO> {
        const id = request.idCart;
        const endpoint = environment.cart.update_cart;
        const httpOptions = { headers: this.headers };
        return this.http
            .put<UpdateCartResponseDTO>(
                `${environment.url_base}/${endpoint}/${id}`,
                request,
                httpOptions,
            )
            .pipe(
                map((result) => {
                    return result;
                })
            );
    }
}
