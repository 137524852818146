import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Localization } from '../shared/Localization';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent implements OnInit {
  constructor(
    public readonly localization: Localization,
    private router: Router
  ) {}

  ngOnInit(): void {}

  onReturnClicked(): void {
    this.router.navigate(['/']);
  }
}
