import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Localization } from 'src/app/shared/Localization';
import { AuthService } from './../service/auth.service';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    localization: Localization;

    constructor(
        private authenticationService: AuthService,
        private router: Router,
        localization: Localization
    ) {
        this.localization = localization;
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((err: HttpErrorResponse) => {
                if (request.url.includes('/api/v1/prepaid/isUserSyncedInBarrier') && err.status === 400) {
                    return of(new HttpResponse({
                        status: 200,
                        body: err.error
                    }));
                }
                if (request.url.includes('/api/v1/auth/oneTimeCode') && err.status === 401) {
                    return throwError(err);
                }

                if (request.url.includes('/api/v1/auth/logInWithOneTimeCode') && err.status === 400) {
                    return throwError(err);
                }

                // Manejo de errores 500
                if (err.status === 500) {
                    localStorage.removeItem('currentUser');
                    this.authenticationService.logout();
                    const error = this.localization.tokenError;
                    this.router.navigate(['/login']);
                    return throwError({ message: error });
                }

                // Manejo de errores 401
                if (err.status === 401) {
                    this.authenticationService.logout();
                    const error = err.error.message;
                    return throwError({ message: error });
                }

                // Propaga el error para otros casos
                const error = err.error.message || err.statusText;
                return throwError({ message: error });
            })
        );
    }
}