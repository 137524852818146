const http = 'https';
const devideportivavipparkingbackend =
  'develop.gs-ideportiva-vipparking-backend';
const url = `${http}://${devideportivavipparkingbackend}.gservicio.com`;

export const environment = {
  production: true,
  token_secret: '4uiyerjfhg478312390122ehdwjhsdsd',
  url_base: `${url}/api/v1`,
  url: `${url}`,

  // MARK: Authentication
  auth: {
    login: 'auth',
    only_admins: 'auth/admins',
    recover_user: 'auth/recovery',
    change_password: 'auth/changePass',
    update_phone: 'auth/updatePhone',
    update_email: 'auth/updateEmail',
    verify_phone: 'auth/verifyPhone',
    verify_email: 'auth/verifyEmail',
    history_login: 'auth/historyLogIn'
  },
  // MARK: Cart
  cart: {
    base: 'cart',
    get_cart: 'cart/getCart',
    empty_cart: 'cart/emptyCart',
    get_my_cart: 'cart/getMyCart',
    pay_my_cart: 'cart/payMyCart',
    delete_cart: 'cart/deleteCart',
    update_cart: 'cart/updateMyCart',
  },
  // MARK: Report
  report: {
    base: 'report',
    download_excel: 'report/excel',
    general_report: 'report/generalReport',
    qr_courtesy_report: 'report/QRCortesia',
    qr_prepaid_report: 'report/QRPrepago',
    infinity_report: 'report/reportInfinityQR',
    report_by_month: 'report/reportByMonth',
    total_qr_prepaid_report: 'report/TotalQRPrepago',
    history_qr_prepaid_report: 'report/HistorialQRPrepago',
    account_status: 'prepaid/accountStatus',
    download_excel_users: 'report/users',
    download_excel_qr_courtesy: 'report/excelQRCortesia',
    account_report: 'report/reportSuperAdminPrepago',
    closing_month: 'report/reportSuperAdminPrepagoByMonth'
  },
  purchased_tickets: 'compraBoletos',
  change_purchased_tickets: 'compraBoletos/changeTicket',
  return_ticket: 'compraBoletos/returnTicket',
  purchased_tickets_user: 'compraBoletos/user',
  purchased_tickets_event: 'compraBoletos/event',
  available_places_endpoint: 'compraBoletos/validateAvailablePlaces',
  discount_code_endpoint: 'discountCode',
  discount_code_valid: 'discountCode/isValid',
  delete_discount_code_endpoint: 'discountCode/delete',
  release_discount_code_endpoint: 'discountCode/releaseOrClose',
  map_event_endpoint: 'eventMapFilter/map',
  event_endpoint: 'eventos',
  addEvent_endpoint: 'eventos/addEvent',
  filter_event_endpoint: 'eventos/filter',
  only_events_endpoint: 'eventos/only/events',
  change_Status_endpoint: 'eventos/changeStatus',
  infinity_events_endpoint: 'eventos/infinity/events',
  events_by_month_endpoint: 'eventos/events/byMonth',
  reschedule_event_endpoint: 'eventos/reschedule/event',
  eventType_endpoint: 'eventType',
  guards_endpoint: 'guards',
  list_Guards_Of_area_endpoint: 'guards/guardArea',
  pago_evento_endpoint: 'metodoPagoUsuario/pago3DSecure',
  confirmacion_evento_endpoint: 'metodoPagoUsuario/pago',
  accept_payment_endpoint: 'metodoPagoUsuario/acceptPayment',
  pago_evento_v2_endpoint: 'metodoPagoUsuario/pago3DSecurev2',
  pago_Mercado_Pago_endpoint: 'metodoPagoUsuario/mercadoPago',
  upgrade_payment_endpoint: 'metodoPagoUsuario/paymentUpgrade',
  approved_payment_endpoint: 'metodoPagoUsuario/approvePayment',
  pago_evento_parche_endpoint: 'metodoPagoUsuario/paymentCallback',
  pago_evento_parche2_v2_endpoint: 'metodoPagoUsuario/v2/2doCallback',
  change_paymentMethod_endpoint: 'metodoPagoUsuario/changePaymentMethod',
  pago_evento_parche_v2_endpoint: 'metodoPagoUsuario/v2/paymentCallback',
  release_tickets_endpoint: 'metodoPagoUsuario/release/releaseUnpaidTickets',
  pendingPayment_Mercado_Pago_endpoint: 'metodoPagoUsuario/pending/mercadoPago',
  pago_prepago_endpoint: 'metodoPagoPrepago/pago3DSecure',
  pago_prepago_v2_endpoint: 'metodoPagoPrepago/pago3DSecurev2',
  pago_prepago_parche_endpoint: 'metodoPagoPrepago/paymentCallback',
  pago_prepago_parche2_v2_endpoint: 'metodoPagoPrepago/v2/2doCallback',
  pago_prepago_parche_v2_endpoint: 'metodoPagoPrepago/v2/paymentCallback',
  referencia_prepago_endpoint: 'prepaid/generaReferenciaDePago',
  add_QR_prepago_endpoint: 'prepaid',
  listar_QR_prepago_endpoint: 'prepaid/fetch',
  hitorial_QR_prepago_endpoint: 'prepaid/historial',
  type_prepaid_endpoint: 'prepaid/type',
  create_type_prepaid_endpoint: 'prepaid/type',
  delete_type_prepaid_endpoint: 'prepaid/type',
  update_type_prepaid_endpoint: 'prepaid/type/update',
  update_prepaid_endpoint: 'prepaid/updateQR',
  all_type_prepaid_endpoint: 'prepaid/typeByAdmin',
  qr_endpoint: 'qr',
  lecturaQR_endpoint: 'qr/readqr',
  registrarQR_endpoint: 'qr/activity',
  generar_QR_cortesia_endpoint: 'qr/cortesia',
  lecturaQRCortesia_endpoint: 'qr/cortesia/readqr',
  listar_QR_cortesia_endpoint: 'qr/cortesia/listar',
  registrarQRCortesia_endpoint: 'qr/cortesia/activity',
  asignar_QR_cortesia_endpoint: 'qr/cortesia/modificar',
  reasignar_QR_cortesia_endpoint: 'qr/qrCourtesy/rescheduleQR',
  enclosure_endpoint: 'recinto',
  eventos_por_recintos_endpoint: 'recinto/events',
  only_enclosure_endpoint: 'recinto/onlyEnclosure',
  only_enclosures_endpoint: 'recinto/only/enclosure',
  only_enclosure_byAdmin_endpoint: 'recinto/onlyEnclosureByAdmin',
  eventos_por_mes_por_recintos_endpoint: 'recinto/events/eventsByMonth',
  register_endpoint: 'register',
  users_endpoint: 'users',
  change_profile_endpoint: 'users/editarPerfil',
  admins_endpoint: 'users/list/admins',
  only_user_endpoint: 'users/list/onlyUsers',
  only_user_prepaid_endpoint: 'users/list/onlyUsersPrepaid',
  logs_endpoint: 'users/logs',
  lista_zonas_endpoint: 'zona',
  zonaEstacionamiento_endpoint: 'zonaEstacionamiento',
  zonas_endpoint: 'zonaEstacionamiento/detail',
  event_areas_endpoint: 'zonaEstacionamiento/evento',
  only_parking_area_endpoint: 'zonaEstacionamiento/only/parkingArea',
  recharge_new_balance_endpoint: 'prepaid/loadBalance',
  migrate_user_endpoint: 'prepaid/migrateUser',
  add_parking_pen: 'prepaid/registerUserByAdminInPen'
};
