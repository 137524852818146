import { Localization } from 'src/app/shared/Localization';
import { AuthService } from 'src/app/auth/service/auth.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { GetOnlyEnclosureByAdminUseCase } from 'src/app/core/usecases/enclosures/GetOnlyEnclosureByAdminUseCase';
import {
  OnlyEnclosureResponse,
} from 'src/app/data/dto/EnclosuresDTO';

declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}

export const routes: RouteInfo[] = [
  {
    path: '/home',
    title: 'Inicio',
    icon: 'fas fa-home',
    class: '',
  }
];

export const routesUsers: RouteInfo[] = [
  {
    path: '/home',
    title: 'Inicio',
    icon: 'fas fa-home',
    class: '',
  },
];


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  idUser: any;

  @Output() nameMenu = new EventEmitter<string>();

  public menuItems: any[];
  idProfile: number;
  idAdmin: any;
  nameProfile = '';
  public isCollapsed = true;
  greet = '';
  userName: any;
  error: any;
  listEnclosureByAdmin: OnlyEnclosureResponse[];
  onlyEnclosures: any = [];
  logo: any;
  style: any;
  styleIcon: any;
  state: any;
  isEvent: boolean;
  isPrepaid: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthService,
    public readonly localization: Localization,
    private readonly getOnlyEnclosureByAdminUseCase: GetOnlyEnclosureByAdminUseCase,
  ) {
    if (localStorage.getItem('idAdmin') == null) {
      this.authenticationService.currentUser.subscribe(
        (currentUser) =>
        (this.idAdmin =
          currentUser != null ? currentUser.response.intFKIDUsuario : 0)
      );
    } else {
      this.idAdmin = Number(localStorage.getItem('idAdmin'));
    }
    if (this.idAdmin !== 1) {
      localStorage.setItem('idAdmin', JSON.stringify(this.idAdmin));
    }
    this.authenticationService.currentUser.subscribe(
      (currentUser) =>
      (this.userName =
        currentUser != null ? currentUser.response.chrNombreUsuario : 0)
    );
  }

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(
      (currentUser) =>
        (this.idUser = currentUser != null ? currentUser.response.intFKIDProfile : 0)
    );
    this.authenticationService.currentUser.subscribe(
      (currentUser) =>
        (this.idProfile = currentUser != null ? currentUser.response.intFKIDUsuario : 0)
    );
    this.authenticationService.currentUser.subscribe(
      (currentUser) =>
        (this.nameProfile = currentUser != null ? currentUser.response.chrNombreUsuario : 0)
    );
    this.router.events.subscribe((event) => {
      this.isCollapsed = true;
    });
    this.checkHrs();
    this.listOnlyEnclosuresByAdmin();
  }

  listOnlyEnclosuresByAdmin(): void {
    console.log(this.idAdmin);
    if (this.idAdmin === 1) {
      this.style = '#05216e';
      this.styleIcon = 'Parkit-icon';
      this.state = 'Monterrey, N.L.';
      this.logo = `assets/img/parkit-logo.png`;
    } else {
      const request = {
        idAdmin: localStorage.getItem('idAdmin')
      };
      this.getOnlyEnclosureByAdminUseCase.execute(request).subscribe(
        (data) => {
          this.listEnclosureByAdmin = data.response;
          this.idAdmin = this.listEnclosureByAdmin[0].idAdmin;
          localStorage.setItem('idAdmin', JSON.stringify(this.idAdmin));
          this.style = this.listEnclosureByAdmin[0].color;
          this.styleIcon = this.listEnclosureByAdmin[0].nameAdmin + '-icon';
          const direction = this.listEnclosureByAdmin[0].chrUbicacion.split(',');
          const location = direction.slice(direction.length - 2);
          this.state = location[0] + ', ' + location[1];
          this.logo = `assets/img/${this.listEnclosureByAdmin[0].nameAdmin}-logo.png`;
          this.isEvent = this.listEnclosureByAdmin[0].isEvent;
          this.isPrepaid = this.listEnclosureByAdmin[0].isPrepaid;
        },
        (error) => {
          this.error = error;
        }
      );
    }
  }

  checkHrs(): void {
    const myDate = new Date();
    const hrs = myDate.getHours();
    if (hrs < 12) {
      this.greet = this.localization.goodMorning;
    } else if (hrs >= 12 && hrs <= 17) {
      this.greet = this.localization.goodAfternoon;
    } else if (hrs >= 17 && hrs <= 24) {
      this.greet = this.localization.goodEvening;
    }
  }

  onHome(): void {
    this.router.navigate(['/']);
  }

  closeLogout(): void {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
    localStorage.clear();
  }

  routerName(name: string): void {
    const nameDefault = 'Inicio';
    if (name === nameDefault) { this.onHome(); }
    this.nameMenu.emit(name);
  }

  password(): void {
    this.router.navigate(['/change-pass']);
  }

  profile(): void {
    this.router.navigate(['/change-profile']);
  }

}
