import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { UserRepository } from '../../../core/repositories/auth/UserRepository';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/auth/service/auth.service';
import {
  LoginDTO,
  LoginResponseDTO,
  RecoveryRequestDTO,
  RecoveryResponseDTO,
  ChangePassRequestDTO,
  ChangePassResponseDTO,
  ChangeProfileRequestDTO,
  ChangeProfileResponseDTO,
  AdminsDTO,
} from '../../dto/LoginDTO';

@Injectable({
  providedIn: 'root',
})
export class UserRestRepository extends UserRepository {
  private headers: HttpHeaders;
  constructor(private http: HttpClient, private auth: AuthService) {
    super();
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
  }

  login(auth: LoginDTO): Observable<LoginResponseDTO> {
    const endpoint = environment.auth.login;
    const httpOptions = { headers: this.headers };
    return this.http
      .post<LoginDTO>(`${environment.url_base}/${endpoint}`, auth, httpOptions)
      .pipe(
        map((user) => {
          return this.auth.login(user);
        })
      );
  }

  logout(): Observable<string> {
    this.auth.logout();
    const subjet = new BehaviorSubject<string>('login');
    return subjet.asObservable();
  }

  recover(request: RecoveryRequestDTO): Observable<RecoveryResponseDTO> {
    const endpoint = environment.auth.recover_user;
    const httpOptions = { headers: this.headers };
    return this.http
      .post<RecoveryResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        JSON.stringify(request),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  changePass(request: ChangePassRequestDTO): Observable<ChangePassResponseDTO> {
    const endpoint = environment.auth.change_password;
    const httpOptions = { headers: this.headers };
    return this.http
      .post<ChangePassResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        JSON.stringify(request),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  changeProfile(request: ChangeProfileRequestDTO): Observable<ChangeProfileResponseDTO> {
    const endpoint = environment.change_profile_endpoint;
    const id = request.id;
    const httpOptions = { headers: this.headers };
    return this.http
      .put<ChangeProfileResponseDTO>(
        `${environment.url_base}/${endpoint}/${id}`,
        JSON.stringify(request),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  admins(): Observable<AdminsDTO> {
    const endpoint = environment.auth.only_admins;
    const httpOptions = { headers: this.headers };
    return this.http
      .get<AdminsDTO>(
        `${environment.url_base}/${endpoint}`,
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

}
