import { Component, Input, Output, EventEmitter } from '@angular/core';

interface AlertButton {
  text: string;
  cssClass?: string;
  emitValue?: any;
  closeAfterClick?: boolean;
}

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss']
})
export class AlertDialogComponent {
  @Input() title: string = 'Atención';
  @Input() message: string;
  @Input() type: 'info' | 'warning' | 'error' | 'success' = 'info';
  @Input() buttons: AlertButton[] = [];
  
  @Output() buttonClick = new EventEmitter<any>();
  @Output() close = new EventEmitter<void>();

  get iconClass(): string {
    switch (this.type) {
      case 'info': return 'fas fa-info-circle';
      case 'warning': return 'fas fa-exclamation-triangle';
      case 'error': return 'fas fa-times-circle';
      case 'success': return 'fas fa-check-circle';
      default: return 'fas fa-info-circle';
    }
  }

  get alertClass(): string {
    switch (this.type) {
      case 'info': return 'alert-info';
      case 'warning': return 'alert-warning';
      case 'error': return 'alert-danger';
      case 'success': return 'alert-success';
      default: return 'alert-info';
    }
  }

  onButtonClick(button: AlertButton): void {
    this.buttonClick.emit(button.emitValue !== undefined ? button.emitValue : button.text);
    if (button.closeAfterClick !== false) {
      this.close.emit();
    }
  }

  onClose(): void {
    this.close.emit();
  }
}