export abstract class Localization {
  appTitle: string;
  appCredentialError: string;
  page404Title: string;
  page404Text: string;
  errorService: string;
  userName: string;
  userPassword: string;
  userRequired: string;
  passwordRequired: string;
  titleHome: string;
  welcomeHome: string;
  headerLogOut: string;
  buttonModalClose: string;
  buttonDeleteModal: string;
  buttonReturn: string;
  buttonSend: string;
  buttonSave: string;
  buttonContinue: string;
  goodMorning: string;
  goodAfternoon: string;
  goodEvening: string;
  menuTitle: string;
  inputRequired: string;
  comboRequired: string;
  registerMinPassword: string;
  registerConfirmpassworRequired: string;
  validEmailRequired: string;
  matchPassword: string;
  registerPhoneRequired: string;
  validNameRequired: string;
  validNumberRequired: string;
  tokenError: string;
}

export class SpanishLocalization implements Localization {
  appTitle = 'Control Traslado';
  appCredentialError = 'El usuario y/o contraseña son incorrectos.';
  tokenError = 'Lo sentimos la sesión caduco, le pedimos iniciar de nuevo para segir disfrutando de la plataforma.';
  page404Title = 'Página no encontrada';
  page404Text = 'Lo sentimos, esta página no esta disponible o no existe';
  errorService = 'Lo sentimos, ocurrio un error';
  userName = 'E-mail / Nombre Usuario:';
  userPassword = 'Contraseña:';
  userRequired = 'Debes ingresar un usuario válido';
  passwordRequired = 'Debes ingresar una contraseña valida';
  titleHome = 'Bienvenido';
  welcomeHome = 'Para comenzar, debes seleccionar una opción en la parte superior.';
  headerLogOut = 'Cerrar sesión';
  buttonModalClose = 'Cerrar';
  buttonDeleteModal = 'Si, eliminar';
  buttonReturn = 'Regresar';
  buttonSend = 'Envíar';
  buttonSave = 'Guardar';
  buttonContinue = 'Continuar';
  goodMorning = 'Buenos días';
  goodAfternoon = 'Buenas tardes';
  goodEvening = 'Buenas noches';
  menuTitle = 'Menú';
  inputRequired = 'Este campo es requerido';
  comboRequired = 'Debes seleccionar una opción';
  registerMinPassword = 'Deben ser al menos 6 caracteres';
  registerConfirmpassworRequired = 'Debes confirmar tu contraseña';
  matchPassword = 'Las contraseñas no coinciden';
  validEmailRequired = 'Debes ingresar un correo válido';
  registerPhoneRequired = 'Debes capturar 10 dígitos';
  validNameRequired = 'El nombre no debe incluir espacios, tampoco números y debe llevar mínimo 5 caracteres';
  validNumberRequired = 'Este campo solo acepta números';
}
