import { UseCase } from '../../base/UserCase';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { EnclosuresRepository } from '../../repositories/enclosures/EnclosuresRepository';
import { OnlyEnclosureByAdmin, OnlyEnclosure } from 'src/app/data/dto/EnclosuresDTO';

@Injectable({
    providedIn: 'root'
})

export class GetOnlyEnclosureByAdminUseCase implements UseCase<OnlyEnclosureByAdmin, OnlyEnclosure> {
    constructor(
        private enclosuresRepository: EnclosuresRepository,
    ) { }

    execute(request: OnlyEnclosureByAdmin ): Observable<OnlyEnclosure> {
        return this.enclosuresRepository.enclosureByAdmin(request);
    }
}
