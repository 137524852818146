import { Observable } from 'rxjs';
import {
    RecintosRequestDTO,
    RecintosResponseDTO,
    EnclosuresRequestDTO,
    EnclosuresResponseDTO,
    UpdateEnclosuresRequestDTO,
    UpdateEnclosuresResponseDTO,
    DeleteEnclosuresRequestDTO,
    DeleteEnclosuresResponseDTO,
    EventsbyvenueRequestDTO,
    EventsbyvenueResponseDTO,
    ZoneByEventsRequestDTO,
    ZoneByEventsResponseDTO,
    EventsByMonthRequestDTO,
    EventsByMonthResponseDTO,
    OnlyEnclosureDTO,
    OnlyEnclosure,
    OnlyEnclosureByAdmin,
} from 'src/app/data/dto/EnclosuresDTO';

export abstract class EnclosuresRepository {
    abstract enclosures(
      request: RecintosRequestDTO
    ): Observable<RecintosResponseDTO>;
    abstract createEnclosures(
      request: EnclosuresRequestDTO
    ): Observable<EnclosuresResponseDTO>;
    abstract updateEnclosures(
      id: UpdateEnclosuresRequestDTO
    ): Observable<UpdateEnclosuresResponseDTO>;
    abstract deleteEnclosures(
      id: DeleteEnclosuresRequestDTO
    ): Observable<DeleteEnclosuresResponseDTO>;
    abstract eventsByEnclosures(
      id: EventsbyvenueRequestDTO
    ): Observable<EventsbyvenueResponseDTO>;
    abstract eventsByMonthByEnclosures(
      id: EventsByMonthRequestDTO
    ): Observable<EventsByMonthResponseDTO>;
    abstract zoneByEvents(
      id: ZoneByEventsRequestDTO
    ): Observable<ZoneByEventsResponseDTO>;
    abstract onlyEnclosures(): Observable<OnlyEnclosureDTO>;
    abstract onlyEnclosure(): Observable<OnlyEnclosure>;
    abstract enclosureByAdmin(
      request: OnlyEnclosureByAdmin
    ): Observable<OnlyEnclosure>;
}
