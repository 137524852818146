import { Observable } from 'rxjs';
import {
    CartRequestDTO,
    CartResponse,
    Cart,
    CartResponseDTO,
    DeleteCartRequestDTO,
    DeleteCartResponseDTO,
    EmptyCartRequestDTO,
    EmptyCartResponseDTO,
    PayCartRequestDTO,
    PayCartResponseDTO,
    UpdateCartRequestDTO,
    UpdateCartResponseDTO,
} from 'src/app/data/dto/CartDTO';

export abstract class CartRepository {
    abstract listCart(
        request: Cart
    ): Observable<CartResponseDTO>;
    abstract createCart(
        request: CartRequestDTO
    ): Observable<CartResponse>;
    abstract deleteCart(
        id: DeleteCartRequestDTO
    ): Observable<DeleteCartResponseDTO>;
    abstract emptyCart(
        id: EmptyCartRequestDTO
    ): Observable<EmptyCartResponseDTO>;
    abstract getMyCart(
        request: Cart
    ): Observable<CartResponseDTO>;
    abstract payMyCart(
        request: PayCartRequestDTO
    ): Observable<PayCartResponseDTO>;
    abstract updateMyCart(
        request: UpdateCartRequestDTO
    ): Observable<UpdateCartResponseDTO>;
}
