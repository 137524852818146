import { Observable } from 'rxjs';
import {
    AddUserDTO,
    ListEventsOfUSerDTO,
    DeleteResponseDTO,
    DeleteUserRequestDTO,
    UpdateResponseDTO,
    UsersRequestDTO,
    UsersResponseDTO,
    UsersResponseResgisterDTO,
    UserUpdateResponseDTO,
    UserIdDTO,
    LogsUserDTO,
    DownloadExcelRequestDTO,
    DownloadExcelDTO,
    OnlyUsersRequestDTO,
    OnlyUsersResponseDTO,
    UserPhoneUpdateDTO,
    UsersResponseUpdatedPhoneDTO,
    UserPhoneVerifyDTO,
    UserEmailUpdateDTO,
    UsersResponseUpdatedEmailDTO,
    UserEmailVerifyDTO,
    UserHistoryLoginDTO,
    UserHistoryLoginResponseDTO
} from '../../../data/dto/UsersDTO';

export abstract class UsersRepository {
    abstract getAllUsers(request: UsersRequestDTO): Observable<UsersResponseDTO>;
    abstract getAllAdmins(): Observable<UsersResponseDTO>;
    abstract updateUser(request: UserUpdateResponseDTO): Observable<UpdateResponseDTO>;
    abstract deleteUser(reques: DeleteUserRequestDTO): Observable<DeleteResponseDTO>;
    abstract addUser(params: AddUserDTO): Observable<UsersResponseResgisterDTO>;
    abstract getAllPurchaseTicket(id: UserIdDTO): Observable<ListEventsOfUSerDTO>;
    abstract getUserById(id: number): Observable<UsersResponseDTO>;
    abstract getLogsById(id: number): Observable<LogsUserDTO>;
    abstract DownloadExcel(file: DownloadExcelRequestDTO): Observable<DownloadExcelDTO>;
    abstract onlyUsers(request: OnlyUsersRequestDTO): Observable<OnlyUsersResponseDTO>;
    abstract onlyUsersPrepaid(request: OnlyUsersRequestDTO): Observable<OnlyUsersResponseDTO>;
    abstract changePhone(request: UserPhoneUpdateDTO): Observable<UsersResponseUpdatedPhoneDTO>;
    abstract verifyPhone(request: UserPhoneVerifyDTO): Observable<UsersResponseUpdatedPhoneDTO>;
    abstract changeEmail(request: UserEmailUpdateDTO): Observable<UsersResponseUpdatedEmailDTO>;
    abstract verifyEmail(request: UserEmailVerifyDTO): Observable<UsersResponseUpdatedEmailDTO>;
    abstract sendHistoryLogin(request: UserHistoryLoginDTO): Observable<UserHistoryLoginResponseDTO>;
}
