import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from 'src/app/auth/service/auth.service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { EventTypeRepository } from 'src/app/core/repositories/eventType/EventTypeRepository';
import { map } from 'rxjs/operators';
import {
    EventTypeResponseDTO,
    EventTypeRequestDTO,
    EventTypeResponse,
    UpdateEventTypeRequestDTO,
    UpdateEventTypeResponseDTO,
    DeleteEventTypeRequestDTO,
    DeleteEventTypeResponseDTO
} from 'src/app/data/dto/EventTypeDTO';

@Injectable({
    providedIn: 'root',
})

export class EventTypeRestRepository extends EventTypeRepository {
    private headers: HttpHeaders;
    constructor(private http: HttpClient, private auth: AuthService) {
        super();
        this.headers = new HttpHeaders({
            'Content-Type': 'application/json',
        });
    }

    listEventType(): Observable<EventTypeResponseDTO> {
        const endpoint = environment.eventType_endpoint;
        const httpOptions = { headers: this.headers };
        return this.http
            .get<EventTypeResponseDTO>(
                `${environment.url_base}/${endpoint}`,
                httpOptions
            )
            .pipe(
                map((result) => {
                    return result;
                })
            );
    }

    createEventType(
        request: EventTypeRequestDTO
    ): Observable<EventTypeResponse> {
        const endpoint = environment.eventType_endpoint;
        const httpOptions = { headers: this.headers };
        return this.http
            .post<EventTypeResponse>(
                `${environment.url_base}/${endpoint}`,
                request,
                httpOptions
            )
            .pipe(
                map((result) => {
                    return result;
                })
            );
    }

    updateEventType(
        request: UpdateEventTypeRequestDTO
    ): Observable<UpdateEventTypeResponseDTO> {
        const endpoint = environment.eventType_endpoint;
        const id = request.id;
        const httpOptions = { headers: this.headers };
        return this.http
            .put<UpdateEventTypeResponseDTO>(
                `${environment.url_base}/${endpoint}/${id}`,
                JSON.stringify(request),
                httpOptions
            )
            .pipe(
                map((result) => {
                    return result;
                })
            );
    }

    deleteEventType(
        request: DeleteEventTypeRequestDTO
    ): Observable<DeleteEventTypeResponseDTO> {
        const endpoint = environment.eventType_endpoint;
        const id = request.id;
        const httpOptions = { headers: this.headers };
        return this.http
            .delete<DeleteEventTypeResponseDTO>(
                `${environment.url_base}/${endpoint}/${id}`,
                httpOptions
            )
            .pipe(
                map((result) => {
                    return result;
                })
            );
    }

}
